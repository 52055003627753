<template>
  <div id="manageOwnner" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Manage Customer</div>
        <v-btn class="theme-btn-even" @click.stop="addItem()">
          <span class="I-create"></span>
          <span>Add Customer</span>
        </v-btn>
      </div>
    </div>    
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="items" >
          <template v-slot:item.action="{ item }" class="text-xs-right">
            <div class="b-action">
              <div class="b-btn">
                <v-btn fab class="btn-edit" @click="editItem(item)">
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- dialog สำหรับ เพิ่ม แก้ไข -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <!-- set form กรอกข้อมูลที่นี้ -->
              <v-flex>
                <v-text-field v-model="formModel.o_name" label="Customer"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn   @click="closeDialog">Cancel</v-btn>
          <v-btn  :loading="loading"  @click="saveToServer">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับลบ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>Delete "{{formModel[Object.keys(formModel)[1]]}}" ?</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn   @click="closeDialog">Cancel</v-btn>
          <v-btn   :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  data: () => ({
    headers: [
      { value: "o_name", text: "Customer", sortable: true },
      { value: "action", text: "", sortable: false }
    ],
    defaultValue: {
      o_name: ""
    },
    query: { $sort: { o_id: -1 } },
    //--end config

    items: [],
    total: 0,
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {},
    mode: "" // มีได้ 2 แบบคือ create กับ edit
  }),
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    }
  },
  async mounted() {
    //init here
    this.renderUI();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        var res = await feathersClientUOA.service("customer").find(this.query);
        this.total = res.total;
        this.items = res.data;


      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      this.mode = "create";
      this.formModel = Object.assign({}, this.defaultValue);
      this.dialog = true;
    },

    async editItem(item) {
      this.mode = "edit";
      this.formModel = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {    
      this.loading = true;
      if (this.mode === "edit") {
        try {
          await feathersClientUOA
            .service("customer")
            .patch(this.formModel.o_id, this.formModel);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
        //  alert(JSON.stringify(this.formModel))
          await feathersClientUOA.service("customer").create(this.formModel);
          this.renderUI();
        } catch (err) {
          alert(JSON.stringify(err))
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        //await feathersClientUOA.service("customer").patch(this.formModel.o_id);
        await feathersClientUOA.service("customer").remove(this.formModel.o_id);
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    }
  }
};
</script>